import React from "react";
import InputText from "../../commun/InputText";
import api from "../../api/Api";
import {useNavigate} from "react-router-dom";
import toast from 'react-hot-toast';

const FormSaveUser = () => {

    const navigate = useNavigate();

    const [nom, setNom] = React.useState("");
    const [prenom, setPrenom] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [mdp, setMdp] = React.useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = {
            nom,
            prenom,
            email,
            mdp
        }

        api.post('/api/users/login', user)
            .then(function (response) {
                // tout est bien enregistrer // serveur ma envoyer le ok code 200
                // sauvegarder les informations du serveur
                localStorage.setItem('user', JSON.stringify(response.data))
                // redirection
                if(response.data.role === "ADMIN"){
                    navigate('/admin')
                }else{
                    navigate('/user')
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.message)
            })

    }

    return (
        <form onSubmit={handleSubmit}>
            <InputText label='nom' type='text' name='nom' onChange={(event) => setNom(event.target.value)}/>
            <InputText label='prenom' type='text' name='prenom' onChange={(event) => setPrenom(event.target.value)}/>
            <InputText label='email' type='email' name='email' onChange={(event) => setEmail(event.target.value)}/>
            <InputText label='mdp' type='password' name='mdp' onChange={(event) => setMdp(event.target.value)}/>
            <button type='submit'>Save</button>
        </form>
    );
};

export default FormSaveUser