import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import SiteLayout from "./layout/SiteLayout";
import Accueil from "./site/Accueil";
import Produits from "./site/Produits";
import Contact from "./site/Contact";
import EspaceClient from "./site/EspaceClient";
import AdminLayout from "./layout/AdminLayout";
import AdminGestionProduit from "./admin/AdminGestionProduit";

const PrivateAdminRoute = ({ element : Component}) => { // code qui va être executer avant l'affichage de notre page
    // localStorage.getItem('user') => vérifier si user et dans le localStorage
    // JSON.parse => convertir le string en objet

    const isAuth = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user')).role === "ADMIN";
    return isAuth ? <Component /> : <Navigate to="/espace-client"/>
}

const PrivateUserRoute = ({ element : Component}) => {
    const isAuth = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user')).role === "USER";
    return isAuth ? <Component /> : <Navigate to="/espace-client"/>
}

function App() {
  return (
      <Router >
          <Routes>
            <Route path="/" element={<SiteLayout />}>
              <Route index element={<Accueil />} />
              <Route path="/produit" element={<Produits />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/espace-client" element={<EspaceClient />} />
            </Route>

          <Route path="/admin" element={<PrivateAdminRoute element={AdminLayout} />} >
              <Route index element={<AdminGestionProduit />} />
          </Route>

          <Route path="/user" element={<PrivateUserRoute element={AdminLayout} />} >
              <Route index element={<AdminGestionProduit />} />
          </Route>

          </Routes>
      </Router>
  );
}

export default App;


/*
<Route path="/" element={<SiteLayout />}>  nom de la template des pages layout

 */