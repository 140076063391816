

class Produit{

    constructor(id, nom, prix, image, description, tail) {
        this.id = id
        this.nom = nom
        this.prix = prix
        this.image = image
        this.description = description
        this.tail = tail
    }
}

export default Produit