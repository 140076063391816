import React    from "react";
import FormSaveUser from "./espaceClient/FormSaveUser";

const EspaceClient = () => {

    return (
        <div>
            <h1>EspaceClient</h1>
            <div className="row">
                <div className="col-md-6">
                    <FormSaveUser />
                </div>
            </div>
        </div>
    )
}

export default EspaceClient