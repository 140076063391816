import React    from "react";

const Produits = () => {

    return (
        <div>
            <h1>Produits</h1>
        </div>
    )
}

export default Produits