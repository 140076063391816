import React    from "react";
import {Link, Outlet} from "react-router-dom";
import {Toaster} from "react-hot-toast";

const SiteLayout = () => {


    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item ">
                                <Link className="nav-link" to="/">Accueil</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/produit">Produits</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/espace-client">Espace Client</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <main>
                <Outlet/>
                <div><Toaster/></div>
            </main>
            <footer>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Special title treatment</h5>
                        <p className="card-text">With supporting text below as a natural lead-in to additional
                            content.</p>
                    </div>
                </div>
            </footer>
        </>

    )
}

export default SiteLayout;